.card-title {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.form-button {
    display: flex !important;
    justify-content: flex-end !important;
    button {
        width: 100% !important;
    }
}

.cards-padding{
  padding: 2rem 2.25rem;
}

.item-row-padding-inline{
  padding-inline: 1rem !important;
}

.item-row-nowrap{
  white-space: nowrap;
}
