//
// Theme style
//
/* @import '@angular/material/prebuilt-themes/deeppurple-amber.css'; */
// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Docs(not mandatory)


// Layout
@import "./core/layout/base/layout";
@import "layout/layout";